<script>
import appConfig from "@/app.config";
import { required, email } from "vuelidate/lib/validators";

/**
 * Forgot Password component
 */
export default {
  page: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      processing:false,
      email: "",
      submitted: false,
      error: null,
      title: "Recoverpwd",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let self = this;
        if(self.processing === true){
          return false;

        }
        self.processing = true;
        self.$axios.post('/reset-password-link' ,{'email':self.email}).then((response)=>{
          if(response.data.status !=true){
            self.triggerSwal(response.data.message, "error")
          }
          else{
            self.triggerSwal(response.data.message, "success")
          }
          self.processing = false;
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        }).finally(function () {
        }); 
      }
    },
  },
};
</script>

<template>
  <div>
    
    <div class="account-page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div>
              <div class="bg-white radius-20 p-3">
                  <div class="text-center mt-2">
                    <h5 class="f-20">Reset Password</h5>
                   
                  </div>
                  <div class="p-2 mt-4">
                    <div
                      class="alert alert-success text-center mb-4"
                      role="alert"
                    >
                      Enter your Email and instructions will be sent to you!
                    </div>
                    <form>
                      <div class="mb-3">
                        <label for="useremail">Email</label>
                        <input
                          type="email"
                          v-model="email"
                          class="form-control"
                          id="useremail"
                          placeholder="Enter email"
                          :class="{
                            'is-invalid': submitted && $v.email.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.email.required"
                            >Email is required.</span
                          >
                          <span v-if="!$v.email.email"
                            >Please enter valid email.</span
                          >
                        </div>
                      </div>
                      <div class="row mb-0">
                        <div class="col-12 text-end">
                          <SubmitButton :processing="processing" text="Reset" @clicked="tryToReset" />
                          <!-- <button class="submit-btn gradientColor border-0 text-white btn radius-30 px-3" type="submit">
                            Reset
                          </button> -->
                        </div>
                      </div>
                      <div class="mt-4 text-center">
                        <p class="mb-0 dont-account">
                          Remember It ?
                          <router-link
                            to="/login"
                            class=""
                            >Login</router-link
                          >
                        </p>
                      </div>
                    </form>
                  </div>
               
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style lang="scss" module></style>
